/* This script operates on PayPeriods#index
 * It preforms the following actions:
 *      It will hide the submit button form button.
 *      It sets the default to the current year
 *      It has a listener for when the year pulldown changes to automatically submit the form
 *      
 *
 */

document.addEventListener("turbo:load", function() {
  PayPeriodScripts.setup();
});



var PayPeriodScripts = {
  setup: function(){
    PayPeriodScripts.indexScripts(),
    PayPeriodScripts.summaryScripts(),
    PayPeriodScripts.invoiceScripts()
  },

  indexScripts: function() {
    PayPeriodScripts.hideSubmitButton();
    year_pull_down = document.querySelector('#date_year');
    month_pull_down = document.querySelector('#date_month');
    year_pull_down?.addEventListener('change', PayPeriodScripts.changePayPeriodYear);
    month_pull_down?.addEventListener('change', PayPeriodScripts.changePayPeriodYear);
  },

  summaryScripts: function () {
    unlock_icon = document.querySelectorAll('.unlock-icon').forEach(function(e) {
      e.addEventListener('click', PayPeriodScripts.toggleUnlockIcon);
    });
    submitted_check = document.querySelectorAll('th.submitted-check').forEach(function(e) {
      e.addEventListener('click', PayPeriodScripts.toggleSubmissionLines);
    });
  },

  invoiceScripts: function  () {
    $('#locked-status').click(PayPeriodScripts.toggleLockDisplay)
  },

  toggleLockDisplay: function(){ 
    lock   = document.getElementById('inv-lock')
    unlock = document.getElementById('inv-unlock')

    lock.classList.remove('d-block')
    lock.classList.add('d-none')

    unlock.classList.remove('d-block')
    unlock.classList.remove('d-none')

    $('#menu-lock-icon').show();
  },



  // hide the submit butoon
  hideSubmitButton: function () {
    dys = document.getElementById("date_year_submit")
    if(dys){ dys.style.display = "none"; }
  },

  // automatically submit the year on the pulldown to change the page
  changePayPeriodYear: function  () {
    this.form.submit();
    return true;
  },

  // toggle the unlock icon to lock
  toggleUnlockIcon: function() {
    lock = $(this).siblings('.lock-icon');
    $(this).toggle();
    lock.toggle();
    return true;
  },

  // switch between all invoice, to unsubmitted invoices, to submitted invoices
  toggleSubmissionLines: function() {
    event.preventDefault();

    table     = $(this).parents('tbody');
    submitted = $(table).find('.green-check').parents('tr');
    pending   = $(table).find('.red-x').parents('tr');
    mtch      =  $(this).prop('classList').value;

    switch (true) {
      case /ppending/.test(mtch):
        $(submitted).toggle();
        $(pending).toggle();
        $(this).removeClass('ppending');
        $(this).addClass('ssub');
        break;

      case /ssub/.test(mtch):
        $(submitted).show();
        $(pending).show();
        $(this).removeClass('ssub');
        $(this).removeClass('ppending');
        break;

      default:
        console.log(mtch);
        console.log(/ppending/.test(mtch));
        $(this).addClass('ppending');
        $(submitted).toggle();
    }

    return false;
  }

};
