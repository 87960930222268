// This script hides the Filter and Clear buttons on the Doctors#index view
// The submitWhenChecked function is not working in webpacker

document.addEventListener("turbo:load", function() {
  doctorStatusScripts.setup();
});

var doctorStatusScripts = {
  setup: function() {
    doctorStatusScripts.hideSubmitButtons();
    doctorStatusScripts.changeFilter();
    doctorStatusScripts.changeSearchFilter();
  },

  hideSubmitButtons: function() {
    doctorStatusScripts.hideSubmitButton('#doctor-filter-submit');
    doctorStatusScripts.hideSubmitButton('#doctor-search-filter-submit');
  },

  hideSubmitButton: function(button_id) {
    const submit_button = document.querySelector(button_id);
    if(submit_button){ submit_button.style.display = "none"; }
  },

  changeFilter: function() {
    const doctor_filter = document.querySelector('#doctor-filter');
    doctor_filter?.addEventListener('change', doctorStatusScripts.changeDisplayedDoctors);
    doctor_filter?.addEventListener('change', doctorStatusScripts.logConsole);
  },

  changeSearchFilter: function() {
    const doctor_filter = document.querySelector('#doctor-search-filter-text');
    const form_element = document.querySelector('#doctor-search-filter');

    console.log('changeSearchFilter Loaded');

    form_element?.addEventListener('hover', alert);
    // form_element?.addEventListener('click', doctorStatusScripts.hideElement());
    // doctor_filter?.addEventListener('change', doctorStatusScripts.logConsole);

  },

  changeDisplayedDoctors: function() {

    this.requestSubmit();
    return true;
  },

  logConsole: function() {
    console.log('test');
  },

  hideElement: function() {
  alert();
  }
};

