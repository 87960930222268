$(document).ready(function() {
    $(ReportsScripts.setup);
});


var ReportsScripts = {
    setup: function() {
        ReportsScripts.hideNonFortuneLines();
        ReportsScripts.hideNonPhysicianLeadLines();
      ReportsScripts.showAll();
    },


  showAll: function function_name() {
    $(document).on('click', '.report_line', function() { ReportsScripts.testThis($(this)); } );

  },

  hideNonFortuneLines: function function_name() {
    $(document).on('click', '.fortune-tag', function() { ReportsScripts.showFortuneShiftLines($(this)); } );
  },

  hideNonPhysicianLeadLines: function function_name() {
    $(document).on('click', '.lead-tag', function() { ReportsScripts.showPhysicianLeadLines($(this)); } );
  },

  hideFortuneShiftLines: function (tag) {
    $('.fortune-count').parents('tr').hide();
  },

  hidePhysicianLeadLines: function (tag) {
    $('.pl-count').parents('tr').hide();
  },

  testThis: function (tag) {
    $('.report_line').show();
  },

  showFortuneShiftLines: function (tag) {
    $('.report_line').hide();
    $('.fortune-count').parents('tr').show();
  },

  showPhysicianLeadLines: function (tag) {
    $('.report_line').hide();
    $('.pl-count').parents('tr').show();
  }

};

