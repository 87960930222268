$(document).ready(function() {
    $(shiftSummaryScripts.setup);
});

var shiftSummaryScripts = {
  setup: function() {
    shiftSummaryScripts.hideWhenClicked();
    shiftSummaryScripts.showAll();
  },

  showAll: function() {
    $(document).on('click', '.doctor-name', function() {
      $('.summary-line').show();
    }
   )},

  hideWhenClicked: function() {
    $(document).on('click', '.summary-line', function() {
      console.log('!!!');
      $(this).hide();

    } );
  }

};
