var ConflictSelection = {

  setup: function() {
    $(document).on('click', '.hard_fail_toggle',   function() { ConflictSelection.toggleHardFail($(this))} );
    $(document).on('click', '.soft_fail_toggle',   function() { ConflictSelection.toggleSoftFail($(this))} );
    $(document).on('click', '.no_conflict_toggle', function() { ConflictSelection.toggleNoConflict($(this))} );
    $(document).on('click', '.preferred_toggle',   function() { ConflictSelection.togglePreferred($(this))} );
  },

  toggleHardFail:   function(shift) { ConflictSelection.setConflict(shift, 'hard_fail');   },
  toggleSoftFail:   function(shift) { ConflictSelection.setConflict(shift, 'soft_fail');   },
  toggleNoConflict: function(shift) { ConflictSelection.setConflict(shift, 'no_conflict');   },
  togglePreferred:  function(shift) { ConflictSelection.setConflict(shift, 'preferred');   },

  setConflict: function(shift, conflict) {
    ConflictSelection.clearColour(shift.closest('li'), conflict)
    console.log(conflict);
  },

  clearColour: function(shift, action) {
    ConflictList.list.forEach(e => shift.removeClass(e) );
    shift.toggleClass( action );
    console.log(action);
  }

}

var ConflictList = {
  list: [ 'hard_fail', 'soft_fail', 'no_conflict', 'preferred', 'bg-danger' ]
}

$(ConflictSelection.setup);
