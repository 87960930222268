function hoverByClass(classname,colorover){
  var colorout="transparent"
  var elms=document.getElementsByClassName(classname);
  var first_element = elms[0].className;

  if(first_element.match(/current/))
    colorout = window.getComputedStyle(elms[0]).getPropertyValue('background-color')

  for(var i=0;i<elms.length;i++){
    elms[i].onmouseover = function(){
      for(var k=0;k<elms.length;k++){
        elms[k].style.backgroundColor=colorover;
      }
    };
    elms[i].onmouseout = function(){
      for(var k=0;k<elms.length;k++){
        elms[k].style.backgroundColor=colorout;
      }
    };
  }

}

document.addEventListener("turbo:load", function() {
  document.querySelectorAll('.doctor').forEach(function(e) {
    var doctor = e.classList[1];
    e.addEventListener('onmouseover', hoverByClass(doctor, 'orange'));
  });
});
