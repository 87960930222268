/**
 * This script is an event handler to automatically submit the doctor status from
 * whenever it is changed.
 *
 */

var doctorStatusScripts = {

  SubmitFormWhenChecked: function() {
    this.form.submit();
    return true;
  }

};

document.addEventListener("turbo:load", function() {

  x = document.querySelectorAll('.status-button').forEach(function(e) {
    e.addEventListener('change', doctorStatusScripts.SubmitFormWhenChecked);
  });

});
