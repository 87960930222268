// Used in the Schedules#index to check all the boxes of the previous clusters schedules
$(document).ready(function() {
  $("#checkedAll").change(function(){
    if(this.checked){
      $(".checkSingle").each(function(){
        this.checked=true;
      })
    }else{
      $(".checkSingle").each(function(){
        this.checked=false;
      })
    }
  });

  $(".checkSingle").click(function () {
    if ($(this).is(":checked")){
      var isAllChecked = 0;
      $(".checkSingle").each(function(){
        if(!this.checked)
           isAllChecked = 1;
      })
      if(isAllChecked == 0){ $("#checkedAll").prop("checked", true); }
    }else {
      $("#checkedAll").prop("checked", false);
    }
  });
});

$(document).ready(function() {
  $('#admin_check').click(function() {
    url = $(this).attr('url');
    $.ajax({ url: url, type: 'put'});
  });
});
