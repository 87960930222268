// This adds : separators to .time-field classes to edit the time when a 6 digit integer is entered
// into the .time-field input box

function addSeparators(variable) {
  var x = document.getElementById(variable);

  regex = /(\d\d):*(\d\d):*(\d\d)/
  match = x.value.match(regex)

  dots = ':'
  output = (((( match[1].concat(dots) ).concat(match[2]) ).concat(dots) ).concat(match[3]) );
  x.value = output
}

function onBlurFunction() {
  addSeparators(this.id);
}

document.addEventListener("turbo:load", function() {
  document.querySelectorAll('.time-field').forEach(function(e) {
    var box = document.getElementById(e.id);
    box.addEventListener('blur', onBlurFunction);
  });
});

