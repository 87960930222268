// This script add 4 to the max range when the min range pulldown on Doctors#edit is changed

var DoctorRange = {
  addFourToMax: function() {
    range_diff = 3;
    min        = document.querySelector('#doctor_min_shift_range');
    max        = document.querySelector('#doctor_max_shift_range');
    max.value  = parseInt(min.value) + range_diff;
  }
}

document.addEventListener("turbo:load", function() {
  x = document.querySelector('#doctor_min_shift_range');
  x?.addEventListener('change', DoctorRange.addFourToMax);
});
