/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import { Turbo } from "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"


Rails.start()
// Turbolinks.start()
Turbo.session.drive = false
ActiveStorage.start()

import "jquery"

import 'bootstrap'

require('../js/checkboxes')

// Not using the scheduling preferences component at this time
// require('../js/column_swap');

require('../js/selections_doctors')
require('../js/schedule_conflict')
require('../js/doctor_range')
require('../js/pay_period')
require('../js/statements')
require('../js/reports')
require('../js/shift_status')
require('../js/shift_summary')
require('../js/doctor_index')
require ('../js/months');
require ('../js/clusters');
require ('../js/doctor_status');

