var PreferenceWeights = {
  list: [ 'away', 'unavailable', 'undesired', 'available', 'desired', 'requested', 'assigned' ]

}

// $(KeystrokeMod.setup);


var HighlightNames = {
  setup: function() {
    var colour_out = 'transparent'
    var colour_in  = 'orange'

    $(document).on('click', '.select_name', function() {
      var doctor = ($(this).attr('class').split(/\s+/)[0]);
      var doctorClass ='.'.concat( doctor );

      $(doctorClass).css("background-color", colour_in);
    } );

    $(document).on('dblclick', 'body', function() {
      $('.select_name').css("background-color", colour_out);
    } );

  }
}

$(HighlightNames.setup);



