$(document).ready(function() {
    $(StatementScripts.setup);
});


var StatementScripts = {
    setup: function() {
        StatementScripts.tableScripts();
        StatementScripts.memoScripts();
    },

    tableScripts: function() {
        StatementScripts.calculateSum();
    },

    calculateSum: function() {
        StatementScripts.setNetCell('total-gross', 2);
        StatementScripts.setNetCell('total-deductions', 3);
        StatementScripts.setNetCell('total-net', 4);
    },

    setNetCell: function(cell_id, column_int) {
        cell = document.getElementById(cell_id);
        if (cell) {
            new_net = StatementScripts.reduceColumn(column_int);
            val = Number(new_net).toFixed(2);
            cell.innerText = '$' + val;
        }
        return null;
    },

    reduceColumn: function(x) {
        return StatementScripts.sumNetColumn(x);
    },

    sumNetColumn: function(col_num = 4) {
        var table = document.getElementById("doctor_invoices");
        let tableRows = Array.from(table.rows).slice(1);
        tableRows.pop();

        let stripDollar = function(x) {
            return x.replace(/[$\,]/g, '');
        }
        let fullReduction = function(cell) {
            return tableRows.reduce((total, row) => {
                return total + parseFloat(stripDollar(row.cells[cell].innerText))
            }, 0)
        };
        return fullReduction(col_num);
    },


  memoScripts: function function_name() {
    StatementScripts.hideMemoSubmit();
    StatementScripts.submitWhenChecked();
  },

  hideMemoSubmit: function  () {
    s = $('.memo-check-submit');
    s.hide();
  },

  submitWhenChecked: function() {
    x = $('.memo-check-box').change(function  () {
      $(this).parents('form').submit();
    });
  }





};
